@import (reference) "colors";
@import (reference) "color-classes.less";
@import (reference) "typography.less";
@import (reference) "variables.less";

header.reorg {
  background: #fff;
  color: @color-gray-primary;
  box-sizing: border-box;
  height: 100px;
  position: relative;

  .gl-desktop {
    display: none;
  }

  .icon-diamond, .icon-diamond-outline {
    vertical-align: middle;
    padding-left: 3px;
    color: @color-teal;
    font-size: 12px;

    &.basic, &.anonymous {
      color: @color-magenta;
    }
  }

  .btn-teal {
    background-color: @color-dark-teal;

    &:hover:not(.disabled) {
      background-color: @color-dark-teal;
    }
  }

  // Trending
  .seasonal-icon {
    position: relative;

    .dicon {
      position: absolute;
      width: 23px;
      height: 23px;
      top: -3px;
      left: 7px;
    }
  }

  .container {
    display: flex;
    line-height: 40px;
    max-width: 1150px;
    margin: 0 auto;

    .tabs {
      display: flex;
      left: -400px;

      .hamburger-brainzy {
        display: none;
      }

      @media (max-width: (@screen-homepage - 1)) {
        .hamburger-brainzy {
          display: block;
        }
      }

      .expanded-background {
        display: none;
      }

      .menu-login-links {
        display: none;
        position: absolute;
        top: 10px;
        right: 15px;

        .btn {
          padding: 6px 15px;
        }

        .signin, .react-signin-topnav {
          border: 0;
          color: @color-text-primary;
        }
      }
    }

    .tabs {
      @media (max-width: (@screen-homepage - 1)) {
        position: fixed;
        max-width: 410px;
        width: 100%;
        z-index: 9992;
      }

      @media (max-width: (506px)) {
        max-width: none;
      }

      .tab {
        .pop-down-search {
          @media (min-width: (1101px)) {
            width: 100%;
          }
          @media (max-width: (1100px)) {
            .popular-searches {
              line-height: 22px;
              margin-top: 5px;
            }
          }
        }

      }

      .guided-library-contain {
        .learning-library-link {
          a {
            color: @color-gray-primary;
            font-size: 18px;
            text-decoration: none;

            &:active {
              color: @color-gray-primary;
            }

            &:after {
              right: 0 !important;
            }

            @media (max-width: (@screen-homepage - 1)) {
              font-size: 20px;
            }
          }
        }

        .guided-lessons {
          @media (max-width: (@screen-homepage - 1)) {
            display: flex !important;
            margin-top: 9px;
            order: 2;
          }
        }

        .dropdown {
          .subjects-dropdown-mixin();
          display: none;
          opacity: 1;
          order: 3;
          @media (max-width: (@screen-homepage - 1)) {
            display: block;
          }

          & > ul {

            & > li {

              .sublinks {
                display: none;
              }

              &.open {
                .sublinks {
                  display: flex;

                }
              }
            }
          }
        }
      }

      .search-form-container {
        z-index: 10000;

        form {
          input {
            width: 460px !important;

            @media (max-width: (1100px)) {
              width: 200px !important;
            }

            @media (max-width: (@screen-homepage - 1)) {
              width: 100% !important;
            }
          }
        }
      }
    }

    .tabs {
      @media (max-width: (@screen-homepage - 1)) {
        position: fixed;
        max-width: 410px;
        width: 100%;
        z-index: 9992;
      }

      @media (max-width: (506px)) {
        max-width: none;
      }

      .tab {
        .pop-down-search {
          @media (min-width: (1101px)) {
            width: 100%;
          }
          @media (max-width: (1100px)) {

            .popular-searches {
              line-height: 22px;
              margin-top: 5px;
            }
          }
        }

      }

      .guided-library-contain {
        .learning-library-link {
          a {
            color: @color-gray-primary;
            font-size: 18px;
            text-decoration: none;

            &:active {
              color: @color-gray-primary;
            }

            &:after {
              right: 0 !important;
            }

            @media (max-width: (@screen-homepage - 1)) {
              font-size: 20px;
            }
          }
        }

        .guided-lessons {
          @media (max-width: (@screen-homepage - 1)) {
            display: flex !important;
            margin-top: 9px;
            order: 2;
          }
        }

        .dropdown {
          .subjects-dropdown-mixin();
          display: none;
          opacity: 1;
          order: 3;
          @media (max-width: (@screen-homepage - 1)) {
            display: block;
          }

          & > ul {

            & > li {

              .sublinks {
                display: none;
              }

              &.open {
                .sublinks {
                  display: flex;

                }
              }
            }
          }
        }
      }

      .search-form-container {
        z-index: 10000;

        form {
          input {
            width: 390px !important;

            @media (max-width: (1100px)) {
              width: 200px !important;
            }

            @media (max-width: (@screen-homepage - 1)) {
              width: 100% !important;
            }
          }
        }
      }
    }

    .tab {
      padding: 30px 10px;
      cursor: pointer;

      .tab-text {
        font-size: 18px;
        line-height: 40px;
        white-space: nowrap;
        position: relative;
        display: inline-block;

        i {
          display: inline-block;
          transition: transform 300ms;
          color: @color-dark-gray9F;
        }

        &:hover {
          color: @color-interactive;
        }
      }

      &.active {
        .tab-text {
          font-weight: bold;

          i {
            transform: rotate(180deg);
            color: @color-interactive;
          }

          &:hover {
            color: @color-text-primary;
            text-decoration: none;
          }
        }
      }

      .dropdown {
        position: absolute;
        top: 50%;
        margin-top: 25px;
        left: 0;
        width: 334px;
        box-sizing: border-box;
        padding: 15px 20px;
        z-index: 9990;
        display: none;
        cursor: default;
        color: @color-gray-primary;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
        background-color: @color-ecru;
        border: solid 1px rgba(0, 0, 0, 0.1);

        .search-bar {
          position: absolute;
        }

        .flex-grid .col {
          flex-basis: 0;
          padding-right: 20px;

          &.left-side {
            min-width: 58%;
            margin-right: 50px;
            border-right: 1px solid @color-light-grayDB;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        .section-header {
          font-size: 16px;
          line-height: 26px;
          margin: 0;
          .font-primary-bold;
        }

        .section-sub-header {
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 0.5px;
          color: @color-dark-gray87;
          margin: 0;
          line-height: 1.25;
          padding: 0 0 5px;
        }

        ul {
          padding: 0 0 20px;
          margin: 0;
          list-style: none;

          li {
            padding: 4px 0;
            font-size: 16px;
            line-height: 22px;

            i.premium {
              color: @color-grayBA;
            }

            &.header {
              line-height: 24px;
              .font-primary-bold;

              .dicon {
                width: 30px;
                height: 30px;
                margin-right: 3px;
              }
            }

            &.extra-space {
              padding-top: 15px;
            }

            .grades-holder {
              overflow: hidden;

              .toggle {
                cursor: pointer;
                color: @color-interactive;

                i {
                  display: inline-block;
                  transform-origin: 50% 50%;
                  transition: transform 500ms;
                }

                &:hover {
                  text-decoration: underline;
                }
              }

              .grades {
                padding-left: 14px;
                margin-top: 5px;
                overflow: hidden;
                max-height: 0;
                opacity: 0;
                transition: max-height 300ms, opacity 300ms;

                a {
                  display: block;
                  font-size: 14px;
                  line-height: 22px;
                }
              }

              &.open {
                .toggle i {
                  transform: rotate(-180deg);
                }

                .grades {
                  max-height: 200px;
                  opacity: 1;
                }
              }
            }
          }

          &.indent-items {
            li {
              padding-left: 37px;

              &.header {
                padding-left: 0;
              }
            }
          }
        }

        &.full-width {
          right: 0;
          width: auto;

          > .flex-grid {
            max-width: 1150px;
            margin: 0 auto;
          }
        }

        .content-types {
          .section-header {
            margin-bottom: 5px;
          }
        }

        .entire-library-link {
          margin-top: 12px;
          font-weight: 600;
        }
      }

      &.active {
        .dropdown {
          display: block;
        }
      }
      .pop-down-search {
        @media(min-width: (@screen-homepage)) {
          background-color: white;
          width: 421px;
          top: 0px;
          left: 2px;
          border: none;
          box-shadow: 0 20px 60px 0 rgba(0, 0, 0, .12), 0 20px 60px 0 rgba(0, 0, 0, .24);
          z-index: -9000;
        }

        .form .solid-button-standard {

          .solid-button-label {
            font-size: 14px;
            padding: 10px 15px;
            margin: 7px;
          }

          @media(max-width: (@screen-homepage - 1)) {
            font-size: 14px;
            padding-left: 10px;

            .solid-button-label{

              font-size: 14px;}
          }
        }

        .pop-down-submit {
          @media(min-width: (@screen-homepage)) {
            float: right;
          }
          @media(max-width: (@screen-homepage - 1)) {
            text-align: center;}
        }

        .submit {
          padding: 10px 40px;
        }
        .popular-searches {
          font-size: 14px;
          line-height: 22px;
          margin-top: 10px;

          @media(max-width: (@screen-homepage - 1)) {
            margin: 10px 0 15px 0;}
        }
        .pop-down-search-form {

          @media(max-width: (@screen-homepage - 1))
          {
            min-height: 500px;
          }
          max-width: 100%;
        }

      }



    }

    .logo-holder {
      padding: 30px 0;
      margin-top: -14px;
      width: 200px;
      margin-right: 20px;
      font-size: 38px;

      .hamburger {
        display: none;
      }

      .icon-edu-logo {
        color: @color-teal;
      }

      .icon-logo-mark {
        display: none;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .learning-library {
      display: flex;
      padding-left: 0;

      .popular-links .section-header {
        display: none;
      }
    }

    .search-form-container {
      position: relative;
      form {
        position: relative;
        z-index: 9991;

        input {
          border-radius: 10px;
          border: 1px solid @color-grayC5;
          width: 425px !important;
          box-sizing: border-box;
          background: #fff;
          padding: 0 10px 0 40px;
          line-height: 40px;
          height: 42px;
          font-size: 16px;
          outline: none;

          &:hover, &:active, &:focus {
            border-color: @color-interactive;
          }

          &.Basic {
            width: 250px !important;
          }

          &.Anonymous {
            width: 300px !important;
          }
        }

        i {
          position: absolute;
          color: @color-interactive;
          top: 0;
          left: 10px;
        }
      }
    }

    .who-we-are {
      position: relative;
      display: none;
    }

    .guided-lessons, .progress-tracker, .kid-mode-button {
      cursor: pointer;
      padding: 30px 10px;

      @media (max-width: (@screen-homepage - 1) ) {
        padding: 0 20px !important;
      }


      a {
        color: @color-gray-primary;
        font-size: 18px;
        text-decoration: none;

        &:hover {
          color: @color-blue-sapphire;
          text-decoration: none;
        }
      }
    }

    .guidance {
      position: relative;
      padding-right: 0;
    }

    .upgrade-link {
      padding: 30px 10px;
      display: none;
    }

    .login-links {
      vertical-align: top;
      flex: 1 auto;
      box-sizing: border-box;
      padding: 30px 0;
      text-align: right;
      display: none;
      white-space: nowrap;

      .signin, .react-signin-topnav {
        color: @color-text-primary;
        font-family: TTNorms, Arial, sans-serif;

        &:hover {
          text-decoration: none;
          color: @color-interactive;
        }
      }
    }


    .spacer {
      flex: 1;
      width: 100%;
      padding: 30px 10px 0 0;
      text-align: right;
      white-space: nowrap;
    }

    .brainzy-access {
      width: 100px;
      display: inline-block;

      .brainzy-link {
        cursor: pointer;
        position: relative;
        &.var_0 {
          height: 50px;
          top: -10px;
          .button {
            position: absolute;
            width: 125px;
            height: 35px;
            border: 1px solid #5672c4;
            box-shadow: 0 0 2px 0 rgba(0,0,0,.06), 0 2px 2px 0 rgba(0,0,0,.12);
            right: 0;
            top: 15px;
            border-radius: 35px;
            transition: 150ms all;
          }
          .button-inner {
            width: 100%;
            height: 55px;
            position: absolute;
            bottom: 0;
          }
          .roly-clip {
            position: absolute;
            left: -1px;
            bottom: 0;
            border-radius: 17px;
            overflow: hidden;
            width: 100px;
            height: 55px;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
          }
          .roly {
            width: 47px;
            height: 44px;
            position: absolute;
            bottom: -1px;
            left: -3px;
            transform-origin: -5px 50px;
            transition: 150ms transform;
          }
          .kids {
            position: absolute;
            right: 8px;
            top: 18px;
            width: 75px;
            height: 37.5px;
            transform-origin: -20px 100%;
            transition: 150ms transform;
          }
        }
        &.var_1 {
          img {
            position: relative;
            width: 100px;
            height: 34px;
            transition: 150ms all;
            margin-top: 2px;
          }
        }
        &:hover {
          &.var_0 {
            .button {
              box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .28);
            }
            .roly {
              transform: scale(1.06);
            }
            .kids {
              transform: scale(1.03);
            }
          }
          &.var_1 {
            img {
              filter: drop-shadow(0 3px 1px rgba(0,0,0,.15));
              transform: scale(1.04);
            }
          }
        }
      }

      &.mobile {
        margin: -5px 0 -5px 30px;
        transform: scale(1.25);
        display: none;
        .brainzy-link.var_0 {
          left: 5px;
          width: 125px;
          margin: 25px 0 15px 0;
        }
        .brainzy-link.var_1 {
          img {
            top: 2px;
          }
        }
        div {
          padding: 0 !important;
        }
      }
      @media (max-width: (@screen-homepage - 1)) {
        display: none;

        &.mobile {
          display: block;
        }
      }
    }

    .hamburger-brainzy .brainzy-access {
      @media (max-width: (@screen-homepage - 1)) {
        display: block;
        margin-left: 50px;
      }
    }

    .user-menu {
      padding: 30px 10px 30px 0;
      max-width: 200px;
      cursor: pointer;
      position: relative;

      .num-notifications {
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        background: @color-dark-teal;
        border-radius: 20px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        vertical-align: middle;

        &:empty {
          visibility: hidden;
        }
      }

      .tab-text {
        text-align: right;
        position: relative;
        white-space: nowrap;

        .email-avatar {
          margin-bottom: 1px;
          padding-right: 35px;
          line-height: 20px;

          .email {
            display: inline-block;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
            font-weight: bold;
            line-height: 24px;
            font-size: 14px;
          }

          .user-icon {
            position: absolute;
            right: 0;
            top: 4px;
          }

          .num-notifications {
            position: absolute;
            right: -4px;
            top: 2px;
          }
        }

        .premium-label {
          line-height: 14px;
          padding-right: 35px;

          .icon-diamond-outline {
            font-size: unset;
          }

          span {
            display: inline-block;
            background: @color-dark-teal;
            color: #fff;
            padding: 3px 4px 1px;
            font-size: 9px;
            line-height: 12px;
            font-weight: bold;
            letter-spacing: 0.5px;
            border-radius: 2px;
            vertical-align: middle;
            text-transform: uppercase;
          }
        }
      }

      .dropdown {
        position: absolute;
        overflow: hidden;
        top: 50%;
        margin-top: 25px;
        right: 0;
        width: 250px;
        box-sizing: border-box;
        padding: 15px 0;
        z-index: 9990;
        cursor: default;
        display: none;
        text-align: right;
        color: @color-gray-primary;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
        background-color: @color-ecru;
        border: solid 1px rgba(0, 0, 0, 0.1);

        &.basic-teacher {
          width: 278px;
        }

        &.premium-parent {
          width: 215px;
        }

        ul {
          padding: 0 40px;
          margin: 0;
          list-style-type: none;
          display: flex;
          flex-direction: column;

          li {
            padding: 5px 0;
            margin: 0;
            display: block;
            line-height: 20px;
            order: 5;

            .icon-diamond, .icon-diamond-outline {
              display: none;
            }

            a {
              display: block;
            }

            .num-notifications {
              margin-right: -27px;
              margin-left: 5px;
            }

            &.hidden {
              display: none;
            }

            &.extra-space-border, &.notifications-row {
              padding-top: 15px;
              margin-top: 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            &.extra-space-border-bottom {
              padding-bottom: 15px;
              margin-bottom: 10px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            &.extra-space {
              margin-top: 20px;
            }

            &.upsell, &.premium-header {
              display: none;
            }

            &.school-admin {
              order: 0;
              padding-bottom: 15px;
              margin-bottom: 10px;
              border-bottom: 1px solid rgba(0, 0, 0, .1);
            }
          }
        }
      }

      &.active {
        .tab-text {
          .email-avatar {
            .num-notifications {
              display: none;
            }
          }
        }

        .dropdown {
          display: block;
        }
      }
    }

    .notification-modal {
      text-align: left;
      cursor: default;

      .modal-header {
        display: flex;
        margin-bottom: 25px;
        width: 100%;
        justify-content: space-between;

        h3 {
          margin: 0;
        }

        .delete-all-notifications {
          display: none;
          color: @color-blue-sapphire;
          font-weight: 600;
          cursor: pointer;
          font-size: 16px;
        }
      }

      .notifications-list {
        border-bottom: 1px solid @color-light-grayED;

        .notification {
          display: flex;
          justify-content: space-between;
          position: relative;
          text-align: left;
          line-height: 20px;
          border-top: 1px solid @color-light-grayED;
          padding: 10px;
          align-items: center;

          a {
            display: flex;
            align-items: center;
          }

          .notification-delete {
            cursor: pointer;
            font-size: 16px;
            color: @color-dark-gray87;
            margin-left: 40px;
            padding: 8px 7px;
            border-radius: 25px;

            &:hover {
              background-color: rgba(86, 114, 196, 0.1);
            }
          }
        }
      }
    }
  }

  &.no-search {
    .search-form-container form {
      @media (min-width: @screen-homepage) {
        display: none;
      }
    }

    .gl-desktop {
      @media (min-width: @screen-homepage) {
        display: block;
      }
    }
  }

  &[data-account-type="Anonymous"] {
    .container {
      .who-we-are {
        display: block;
      }

      .login-links {
        display: block;
      }
    }
  }

  &[data-account-type="Basic"] {
    .container {
      .upgrade-link {
        display: block;
        white-space: nowrap;
      }

      .user-menu {
        .tab-text {
          margin-top: 10px;
          margin-bottom: -10px;

          .premium-label {
            display: none;
          }

          .email-avatar {
            .user-icon {
              top: -3px;
            }

            .num-notifications {
              top: -5px;
            }
          }
        }

        .dropdown {
          ul {
            li {
              .icon-diamond, .icon-diamond-outline {
                display: inline-block;
                margin-right: 5px;
              }
            }

            li.premium {
              order: 10;
            }

            li.premium.disabled {
              color: silver;
            }

            li.notifications-row {
              border-top: 0;
              padding-top: 5px;
              margin-top: 0;
            }

            li.premium-header {
              order: 8;
              display: block;
              margin-top: 10px;
              border-top: 1px solid rgba(0, 0, 0, .1);
              padding-top: 15px;
              padding-bottom: 10px;

              span {
                text-transform: uppercase;
                color: @color-dark-gray87;
                font-size: 9px;
                font-weight: bold;
                line-height: 12px;
                letter-spacing: 1px;
                vertical-align: middle;
              }

              i {
                vertical-align: middle;
              }
            }

            li.upsell {
              order: 15;
              display: block;
              padding-top: 15px;

              a {
                display: block;
                font-size: 14px;
                padding: 6px 16px;
              }
            }

            li.tools-for-teaching {
              order: 16;
              padding-top: 7px;
            }

            li.signout {
              order: 20;
            }
          }
        }
      }
    }
  }

  /*!
   * Hamburgers
   * @description Tasty CSS-animated hamburgers
   * @author Jonathan Suh @jonsuh
   * @site https://jonsuh.com/hamburgers
   * @link https://github.com/jonsuh/hamburgers
  */

  .hamburger {
    padding: 15px 10px 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }

  .hamburger:hover {
    opacity: 0.7;
  }

  .hamburger-box {
    width: 26px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: 8px;
  }

  .hamburger-inner, .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: @color-gray-primary;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  .hamburger-inner::after {
    content: "";
    display: block;
  }

  .hamburger-inner::after {
    bottom: -10px;
  }

  .hamburger--slider .hamburger-inner {
    top: 0px;
  }

  .hamburger--slider .third-line {
    top: 10px;
  }

  .hamburger--slider .hamburger-inner::after {
    top: 10px;
  }

  &.expanded {
    .hamburger--slider .hamburger-inner.first-line {
      transform: translate3d(0, 5px, 0) rotate(45deg);

      &:after {
        display: none;
      }
    }

    .hamburger--slider .hamburger-inner.third-line {
      transform: translate3d(0, -5px, 0) rotate(-45deg);

      &:after {
        display: none;
      }
    }

    .container {
      .logo-holder {
        @media (max-width: (506px)) {
          width: 105px !important;
        }
      }

      .tabs {
        .guided-library-contain {
          @media (max-width: (@screen-homepage - 1)) {
            display: block;
            height: 100%;
            overflow-y: auto;
          }

          .dropdown {
            @media (max-width: (@screen-homepage - 1)) {
              left: -30px;
              opacity: 1;
              position: static;
              right: auto;
              z-index: 9991;
            }
          }
        }
      }
    }
  }
}

nav.reorg-subnav {
  height: 48px;
  line-height: 48px;
  overflow: visible;
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background: @color-ecru;

  a {
    color: @color-gray-primary;
    white-space: nowrap;
  }

  .container {
    max-width: 1150px;
    margin: 0 auto;
    color: @color-gray-primary;
    display: flex;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        display: inline-block;
        vertical-align: top;
        flex: 1 auto;
        box-sizing: border-box;
        padding: 0 10px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          width:max-content;
        }

        &.header {
          color: @color-dark-gray87;
        }

        // Secondary nav
        .seasonal-icon {
          position: relative;
          padding-left: 20px;

          .dicon {
            position: absolute;
            width: 23px;
            height: 23px;
            top: -3px;
            left: -5px;

            @media (max-width: (@screen-homepage)) {
              top: -4px;
            }
          }
        }
      }
    }

    .popular {
      display: inline-block;
      vertical-align: top;
      flex: 1 auto;
      box-sizing: border-box;
      margin-right: 90px;

      li {
        flex-grow: 0;
      }

      .header .extra-text, .extra-text-small {
        display: none;
      }

      .top-nav-seasonal-icon-only {
        display: none;

        @media (max-width: 493px) {
          display: inline-block;
          padding-left: 12px;
        }
      }

      .top-nav-seasonal {
        @media (max-width: 493px) {
          display: none;
        }
      }

      .short-nav {
        display: none;

        @media (max-width: 505px) {
          display: inline-block;
        }
      }
    }

    .grades ul {
      display: inline-block;
      vertical-align: top;
      flex: 1 auto;
      box-sizing: border-box;
    }
  }

  &.unified-subnav {
    background-color: @color-ecru;
    box-shadow: none;
    box-sizing: border-box;
    height: 48px;
    line-height: 29px;
    padding: 0 10px;
    z-index: 9001;

    @media (max-width: (@screen-homepage - 1)) {
      position: static;
    }

    .unified-subnav-container {
      max-width: 1150px;
      margin: 0 auto;
      position: relative;
      width: 100%;

      .popular {
        height: 34px;
        overflow: hidden;
        padding-top: 5px;
        padding-left: 20px;
        text-align: left;

        &.links {
          &.hide-small {
            a {
              @media (max-width: 480px) {
                display: inline-block;
              }
            }

            .pumpkin-icon {
              vertical-align: middle;
            }
          }
        }

        ul {
          li {

            @media (max-width: 750px) {
              line-height: 22px;
            }

            a {
              font-size: 16px;
              user-select: none;
              display: inline-block;
              vertical-align: middle;
              color: @color-gray4;
              padding: 0 7px;

              img {
                @media (max-width: 750px) {
                  padding-top: 4px;
                }
              }
              svg {
                @media (max-width: 750px) {
                  padding-top: 5px;
                }
              }

              &:hover {
                background-color: unset;
              }
            }

            .art-contest-icon {
              height: 25px;
              width: 25px;
            }

            .art-contest-link {
              display: flex;
            }

            .art-contest-text {
              display: inline;
              vertical-align: text-bottom;
              padding-top: 1px;
              padding-left: 3px;
            }
          }
        }
      }

      .subnav-more {
        width: 30px;
        text-align: center;

        .icon-menu {
          color: @color-gray4;
          cursor: pointer;
          line-height: 37px;
          z-index: 104;
          position: relative;
        }

        .dropdown {
          text-align: left;
          display: none;
          position: absolute;
          top: 0;
          padding-top: 34px;
          right: 0;
          background: #fff;
          box-shadow: 0px 3px 3px 1px rgba(0,0,0,0.4);
          line-height: 1.3em;
          z-index: 5900000;

          > ul {
            list-style-type: none;
            margin: 0;
            padding: 0 0 25px;
            box-sizing: border-box;
            width: 170px;
            z-index: 95;

            > li {
              margin: 0;
              box-sizing: border-box;
              vertical-align: middle;

              > span {
                font-size: 15px;
                font-weight: bold;
                padding: 9px 6px;
                display: block;
                box-sizing: border-box;
                vertical-align: middle;
                cursor: pointer;

                i.icon-angle-left {
                  margin-right: 3px;
                  font-size: 17px;
                }
                i.icon-angle-down {
                  display: none;
                }
                a {
                  color: @color-gray4;
                  &:hover {
                    color: @color-gray4;
                  }
                }
              }

              > a {
                font-size: 15px;
                font-weight: bold;
                padding: 9px 6px 9px 12px;
                display: block;
                box-sizing: border-box;
                vertical-align: middle;
                cursor: pointer;
                color: @color-gray4;

                &:hover {
                  background: @color-grayF2;
                }
              }

              .sublinks {
                display: none;
                position: absolute;
                box-sizing: border-box;
                padding: 8px 0;
                top: 0;
                bottom: 0;
                right: 100%;
                width: 300px;
                overflow-y: auto;
                overflow-x: hidden;
                background: #fff;
                z-index: 90;
                box-shadow: 0px 3px 3px 1px rgba(0,0,0,0.4);

                ul {
                  margin: 0;
                  padding: 24px 10px 10px;
                  list-style-type: none;
                  display: inline-block;

                  li {
                    margin: 0;
                    padding: 0;

                    &.header {
                      font-size: 16px;
                      padding: 0 0 8px;
                    }

                    a {
                      font-size: 13px;
                      display: block;
                      padding: 0 0 6px;
                      color: @color-gray4;

                      &:hover {
                        background: @color-grayF2;
                      }
                    }
                  }
                }
              }

              &.open {
                background: @color-grayD;

                .sublinks {
                  display: flex;
                }
              }
            }
          }
        }

        &.open {
          .dropdown {
            display: block;
          }
          .icon-menu {
            z-index: 5900001;
          }

          @media (max-width: 479px) {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 200px;
            background: #fff;
            box-shadow: 0px 3px 3px 1px rgba(0,0,0,0.4);
            z-index: 5900000;

            .icon-menu {
              position: absolute;
              top: 0;
              right: 5px;
            }
            .dropdown {
              box-shadow: none;

              > ul {
                width: 200px;

                > li {
                  border-bottom: 1px solid @color-grayF2;

                  &.open {
                    background: transparent;
                  }

                  &:last-child {
                    border-bottom: 0;
                  }

                  .sublinks {
                    position: static;
                    width: 100%;
                    box-shadow: none;
                    padding-top: 0;

                    ul {
                      padding: 0 12px;
                      display: none;

                      .header {
                        display: none;
                      }
                      strong {
                        font-weight: normal;
                      }

                      &:first-child {
                        display: block;
                      }
                    }

                  }

                  > span {
                    padding-left: 12px;

                    i.icon-angle-left {
                      display: none;
                    }
                    i.icon-angle-down {
                      display: block;
                      float: right;
                      font-size: 17px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .subnav-more {
        background-color: @color-ecru;
        padding-top: 5px;

        @media (max-width: (@screen-homepage - 1)) {
          position: absolute;
          top: 0;
          left: 0;
        }

        @media (max-width: 750px) {
          padding-top: 0;
        }


        @media (max-width: (@screen-phone-lg - 1)) {
          background-color: @color-ecru;
          bottom: auto;
          box-shadow: none;
          right: auto;
          width: auto;
        }


        .hamburger {
          @media (max-width: (@screen-homepage - 1)) {
            display: none;
          }
        }

        &.open {
          z-index: 9001;

          .dropdown {
            left: -30px;
            opacity: 1;
            right: auto;
            z-index: 9991;

            & > ul {
              & > li {
                &.open {
                  @media (max-width: (@screen-homepage - 1)) {
                    background-color: transparent;
                  }

                  > span {
                    font-weight: bold;

                    &:hover {
                      color: white;

                      @media (max-width: (@screen-homepage - 1)) {
                        color: @color-gray6;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .dropdown {
          .subjects-dropdown-mixin();
          @media (max-width: (@screen-homepage - 1)) {
            display: none;
          }
        }
      }
    }
  }
  .hamburger {
    padding: 2px 0 0 0;
    display: inline-block;
    cursor: pointer;
    border: 0;
    margin: 0;
    position: relative;
    z-index: 9992;
  }

  .hamburger-box {
    width: 26px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .line {
    width: 25px;
    height: 2px;
    background-color: @color-gray-primary;
    border-radius: 4px;
    margin-top: 6px;
  }
}

.guided-library-contain {
  display: flex;
  flex-direction: row-reverse;
  @media(max-width: (@screen-homepage - 1)) {
    flex-direction: column;
    flex-shrink: 0;
  }
}

@media (max-width: 1199px) {
  header.reorg {
    .container {
      padding: 0;

      .tab {
        .tab-text {
          i {
            margin-left: -2px;
          }
        }

        .dropdown {
          .flex-grid .col {
            padding-right: 8px;

            &.left-side {
              min-width: 60%;
              margin-right: 30px;

              > .flex-grid > .col:first-of-type {
                flex-basis: 8%;
              }
            }
          }
        }
      }

      .upgrade-link {
        padding: 30px 5px;

        a {
          padding: 6px 12px;
          font-size: 15px;
        }
      }

      .login-links {
        .signin, .create-account {
          //padding: 10px 14px;
        }
      }


    }

    &[data-account-type="Anonymous"], &[data-account-type="Basic"] {
      .container {
        .logo-holder {
          width: 200px;
          margin-right: 10px;
          font-size: 32px;
          margin-top: -10px;
        }

        .user-menu .tab-text .email-avatar .email {
          max-width: 120px;
        }

        .tab {
          padding: 30px 10px;
        }

        .learning-library {
          .tab-text {
            margin-right: 5px;
          }
        }

        .search-form-container {
          form {
            input {
              font-size: 15px;
              padding-left: 25px;
              padding-right: 5px;
            }

            i {
              left: 5px;
            }
          }
        }
      }
    }

    &[data-account-type="Basic"] {
      .container {
        .tab {
          padding: 30px 3px;
        }
      }
    }
  }

  nav.reorg-subnav {
    font-size: 14.8px;
    padding: 0 25px;

    .container {
      ul {
        li {
          padding: 0 10px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }

      .popular {
        margin-right: 30px;
      }
    }
  }
}

@media (max-width: 1100px) {
  header.reorg {
    .container {
      .search-form-container {
        form {
          input {
            &.top-nav-search {
              display: inline-block;
              padding-left: 30px;
              padding-right: 10px;
            }

          }
        }
      }
    }

  }

  nav.reorg-subnav {
    .container {
      ul {
        li {
          padding: 0 6px;
        }
      }
    }
  }
}

@media (max-width: (@screen-homepage - 1) ) {
  header.reorg {
    height: 80px;

    .container {
      padding: 0 20px;

      .search-form-container {
        form {
          input {
            display: none;

            .solid-button-standard .hidden {
              display: none;
            }
          }
        }
      }

      .tabs {
        background: @color-ecru;
        left: -400px;
        opacity: 0;
        transition: left 300ms, opacity 300ms;
        position: fixed;
        top: 0;
        padding-top: 115px;
        bottom: 0;
        width: 320px;
        flex-direction: column;
        z-index: 9992;
        overflow-y: auto;

        .expanded-background {
          position: fixed;
          top: 0;
          height: 60px;
          width: 320px;
          background-color: @color-ecru;
          z-index: 9992;
        }
      }

      .tab {
        padding: 0 20px !important;

        .tab-text {
          font-size: 20px;
          display: inline;

          i {
            float: right;
          }

          &:hover {
            text-decoration: none;
          }
        }

        .dropdown {
          position: relative;
          left: 0;
          top: 0;
          width: 100%;
          box-shadow: none;
          padding: 0;
          margin-bottom: 5px;
          display: block;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          border-width: 0 0 1px 0;
          border-bottom: 1px solid rgba(0, 0, 0, .1);
          transition: max-height 300ms, opacity 300ms;
          margin-top: 0;


          .section-sub-header {
            opacity: .65;
          }

          .section-header {
            opacity: .65;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2px;
          }


          .entire-library-link {
            margin-top: 0;
            padding-top: 0;
          }

          ul {

            li {
              &.extra-space {
                padding-top: 0;
              }

              a {
                font-weight: normal;
                .font-primary;
              }
            }
          }

          .flex-grid {
            .col {
              display: block;
            }

            .col.left-side {
              margin-right: 0;
              border-right: 0;
              margin-bottom: 20px;
            }
          }
        }

        &.active {
          .tab-text {
            font-weight: normal;
          }

          .dropdown {
            max-height: 1000px;
            opacity: 1;
          }
          .pop-down-search {

            @media (max-width: (@screen-homepage - 1)) {
              left: -10px;
              width: 115% !important;
              top: 50px;
              background-color: @color-ecru;
              border-bottom: none;
              font-size: 14px;
              line-height: 1.5;
            }
          }
        }
      }

      .guided-lessons, .progress-tracker, .kid-mode-button {
        margin-bottom: 5px;
        padding: 0 20px !important;

        a {
          font-size: 20px;
        }
      }


      .logo-holder {
        padding: 12px 0 0;
        font-size: 30px;
        margin-top: -4px !important;
        position: absolute;
        left: 10px;
        top: -5px;
        z-index: 9993;

        .icon-edu-logo, .icon-logo-mark {
          vertical-align: -1px;
        }

        .hamburger {
          display: inline-block;
          margin-top: -5px;
          margin-right: 20px;
        }
      }

      .learning-library {
        display: block;

        .flex-grid {
          display: block;
        }

        .popular-links {
          ul {
            display: block;

            li {
              display: none;
            }

            padding: 0;


            &.display-links-mobile {
              li {
                display: block;
              }
            }

          }

          .section-header {
            display: block;
          }

          .grades-section {
            margin-top: 20px;
          }
        }

        .subjects-trending {
          ul {
            margin-bottom: 10px;
          }
        }

        .col.content-types {
          .section-header, .section-sub-header, ul:not(.has-entire-library-link), li:not(.entire-library-link) {
            display: none;
          }
        }

        ul.has-entire-library-link {
          padding: 0 0 15px;
        }
      }

      .search-form-container {

        @media(max-width: @screen-desktop)
        {
          top: -115px;
        }

        form {
          z-index: 9991;
          position: absolute;
          top: 62px;
          left: 10px;
          width: 90%;
          box-sizing: border-box;
          padding: 0 15px;

          input {
            width: 100% !important;
            padding-left: 35px !important;
          }

          i {
            left: 25px !important;
          }

          span.twitter-typeahead {
            position: absolute !important;
            width: ~"calc(100% - 15px)";
          }
        }
      }

      .upgrade-link {
        padding: 20px 5px;
      }

      .login-links {
        padding: 20px 5px 20px 0;
      }

      .user-menu {
        padding: 20px 10px 20px 0;
      }
    }


    &.expanded {
      .container {
        .tabs {
          left: 0;
          opacity: 1;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);

          .expanded-background {
            display: block;
          }
        }

        .logo-holder {
          position: fixed;
        }
      }
    }
  }

  nav.reorg-subnav {
    padding: 0 20px;

    .container {
      margin: 0;
      font-size: 15px;

      .popular {
        margin-right: 0;

        li {
          &.header {
            .extra-text {
              display: inline;
            }
          }
        }
      }

      .grades {
        display: none;
      }
    }
  }
}

@media (max-width: 750px) {
  header.reorg {
    .container {
      .tabs {
        left: -800px;
      }

      .upgrade-link {
        display: none !important;
      }

      .user-menu {
        width: 170px;

        .tab-text {
          .email-avatar {
            .email {
              max-width: 110px;
            }
          }
        }
      }
    }
  }

  nav.reorg-subnav {
    .container {
      padding: 6px 0;
      max-width: none;
      text-align: left; //center;
      ul {
        display: block;
      }

      .popular {
        li {
          font-size: 14px;
          line-height: 17px;

          &.header {
            display: block;
            font-size: 12px;
            line-height: 18px;
            padding-left: 6px;

            .extra-text {
              display: none;
            }

            .extra-text-small {
              display: inline;
            }
          }

          &.back-to-school-topnav {
            margin-top: -5px;
          }
        }
      }

      .grades {
        display: none;
      }
    }
  }
}

.react-signup-topnav {

  &.mobile {
    display: none;

    @media (max-width: 320px) {
      display: inline-block;
    }
  }

  @media (max-width: 320px) {
    display: none;
  }
}

@media (max-width: 506px) {
  header.reorg {
    height: 55px;

    .hamburger {
      padding: 10px 0 10px 5px;
    }

    .container {
      padding: 0 10px;

      .user-menu {
        z-index: 9993;

        &.active {
          background: transparent;
        }

        &:hover {
          background: transparent;
        }

        width: 50px;
        height: 28px;
        padding: 14px 10px 14px 0;

        .tab-text {
          margin-top: 0 !important;

          .email-avatar {
            .email {
              display: none;
            }

            .user-icon, .num-notifications {
              top: 0;
            }
          }

          .premium-label {
            display: none;
          }
        }

        .dropdown {
          right: -5px;
        }
      }

      .tabs {
        width: 100%;

        .expanded-background {
          width: 100%;
        }
      }

      .login-links {
        z-index: 99998;
        top: 2px;
        right: 10px;
        padding: 7px 0;
        transition: top 300ms, opacity 300ms;

        .signin-link, .signin-topnav, .react-signin-topnav {
          display: none;
        }

        .signin-linkAB {
          display: inline;
          padding: 6px 15px;
        }

        .create-account-link, .react-signup-topnav {
          padding: 6px 15px;
        }

        .create-account-linkAB {
          display: none;
        }
      }

      .logo-holder {
        padding: 0;
        margin-top: 0 !important;
        z-index: 99999;

        .hamburger {
          margin-top: 0;
          margin-right: 20px;
        }
      }

      .upgrade-link {
        padding: 7px 0;
      }
    }

    &.expanded {
      .container {
        .user-menu {
          position: fixed;
          right: 0;
        }
      }
    }

    &[data-account-type="Anonymous"] {
      .container {
        .tabs {
          .menu-login-links {
            display: block;
            position: fixed;
            z-index: 9993;
            margin-top: -3px;


            .create-account-linkAB {
              opacity: 1;
              font-family: TTNorms, Arial, sans-serif;

              &:hover {
                text-decoration: none;
                color: @color-interactive;
              }
            }

            .signin-link, .react-signin-topnav {
              font-family: TTNorms, Arial, sans-serif;

              &:hover {
                text-decoration: none;
                color: @color-interactive;
              }
            }

            .signin-linkAB {
              opacity: 0;
            }

          }
        }
      }

      &.expanded {
        .container {
          .login-links {
            display: none;
          }

          .logo-holder {
            width: 105px;

            .icon-logo-mark {
              display: inline-block;
              color: @color-teal;
            }

            .icon-edu-logo {
              display: none;
            }
          }
        }
      }
    }
  }

  nav.reorg-subnav {
    .long-nav {
      display: none !important;
    }
  }
}

@media (max-width: 359px) {
  header.reorg {
    .container {
      .user-menu {
        position: static;

        .tab-text {
          .email-avatar {
            .user-icon, .num-notifications {
              top: -4px;
            }
          }
        }

        .dropdown, .dropdown.basic-teacher, .dropdown.premium-parent {
          width: 100%;
          right: 0;
        }
      }

      .logo-holder {
        .icon-logo-mark, .icon-edu-logo {
          font-size: 30px;
        }
      }
    }
  }

  nav.reorg-subnav {
    padding: 0 5px;
  }
}


.Header {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0;
  height: 80px;
  border-bottom: 1px solid @color-grayD;
  align-items: center;
  justify-content: center;
}

.NavBar {
  display: flex;
  align-items: center;
  padding-right: (@spacing-unit * 2);
  padding-left: (@spacing-unit * 2);
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: (@screen-lg-min - (@spacing-unit * 3));

  @media (min-width: @screen-md-min) {
    padding-right: (@spacing-unit * 3);
    padding-left: (@spacing-unit * 3);
  }
}

.NavBar__logo {
  width: 1.5em;
  min-width: 1.5em;
  font-size: 38px;
  color: @color-primary;
  margin-top: -10px;

  &:hover,
  &:active {
    color: @color-primary;
  }

  .icon-edu-logo {
    display: none;
  }

  @media (min-width: @screen-sm-min) {
    width: 200px;
    min-width: 200px;

    .icon-edu-logo {
      display: block;
    }

    .icon-logo-mark {
      display: none;
    }
  }
}

.NavBar__spacer {
  flex-grow: 1;
}

.NavBar__profile {
  display: flex;
  align-items: center;
  margin: 0 (@spacing-unit * 2);
  font-weight: bolder;
  min-width: 0;
}

.NavBar__profile-email {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .hide-on-mobile {
    @media (max-width: 750px) {
      display: none;
    }
  }
}

.NavBar__logout {
  text-align: center;
}

main#content {
  min-height: 400px;
}

.third-line {
  top: 10px;
}

.subjects-dropdown-mixin() {
  background-color: white;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;
  display: block;
  left: -400px;
  opacity: 0;
  right: auto;
  user-select: none;
  z-index: -1;

  @media (max-width: (@screen-homepage - 1)) {
    background-color: @color-ecru;
    border: 0;
    box-shadow: none;
    overflow-y: auto;
    padding-top: 0;
    padding-left: 0;
    position: fixed;
    right: auto;
    transition: left .3s,opacity .3s;
    top: 220px;
  }

  & > ul {
    list-style: none;
    margin-top: 20px;
    padding: 0 0 30px 0;
    width: 250px;

    @media (max-width: (@screen-homepage - 1)) {
      margin-top: 4px;
    }

    & > li {
      background: none;
      border-right: 1px solid @color-light-grayDB;
      padding: 5px 0;

      @media (max-width: (@screen-homepage - 1)) {
        border-right: 0;
        border-bottom: 0;
        padding: 0;
        margin-bottom: 5px;
      }

      &.open {
        background-color: @color-blue-sapphire;

        @media (max-width: (@screen-homepage - 1)) {
          background-color: transparent;
        }

        & > span {
          color: white;

          @media (max-width: (@screen-homepage - 1)) {
            color: @color-gray-primary;
            padding-bottom: 0;
          }

          i {
            &.icon-angle-up {
              @media (max-width: (@screen-homepage - 1)) {
                display: inline-block;
              }
            }

            &.icon-angle-down {
              display: none;
            }
          }
        }
      }

      & > span, & > a {
        color: @color-gray-primary;
        font-size: 20px;
        font-weight: normal;
        padding-left: 30px;

        &:hover {
          color: @color-blue-sapphire;
          font-weight: bold;

          @media (max-width: (@screen-homepage - 1)) {
            color: @color-gray6;
          }
        }

        @media (max-width: (@screen-homepage - 1)) {
          font-size: 20px;
          margin-bottom: 5px;
          padding: 0 20px 10px;
        }

        i {
          &.icon-angle-up {
            display: none;
          }

          &.icon-angle-down {
            display: none;
            @media (max-width: (@screen-homepage - 1)) {
              display: inline-block;
            }
          }

          &.icon-angle-right {
            display: inline-block;
            @media (max-width: (@screen-homepage - 1)) {
              display: none;
            }
          }
        }
      }

      .sublinks {
        background-color: white;
        box-shadow: 4px 3px 1px 0 rgba(0, 0, 0, 0.12), 4px 2px 2px 0 rgba(0, 0, 0, 0.14), 4px 1px 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0 4px 4px 0;
        left: 100%;
        width: 400px;

        @media (max-width: (@screen-homepage - 1)) {
          background-color: @color-ecru;
          box-shadow: none;
          margin: 0 0 0 10px;
          position: static;
        }

        ul {
          margin: 31px 0 0 10px;
          list-style: none;

          @media (max-width: (@screen-homepage - 1)) {
            display: none;
            margin: 0;
            padding: 0 0 0 13px;

            &:first-of-type {
              display: inline-block;
            }
          }

          li {
            color: @color-teal;
            font-family: inherit;
            padding: 5px 0;
            width: 100%;

            @media (max-width: (@screen-homepage - 1)) {
              padding: 0;
            }

            &.header {
              font-weight: bold;
              @media (max-width: (@screen-homepage - 1)) {
                display: none;
              }
            }

            a {
              color: @color-gray6;
              font-size: 16px;

              &:hover {
                background: unset;
              }

              @media (max-width: (@screen-homepage - 1)) {
                color: @color-blue-sapphire;
                line-height: 22px;
                margin: 5px 0;
              }
            }
          }
        }
      }
    }
  }
}
